/*eslint-disable*/
import { Grid, TextField } from "@mui/material";
import React from "react";



export default function MiseADispo({ handleChange, formFields }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Guest Name"
          value={formFields.guestName}
          onChange={(event) => handleChange('guestName', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Phone Number"
          value={formFields.phoneNumber}
          onChange={(event) => handleChange('phoneNumber', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Pax"
          value={formFields.pax}
          onChange={(event) => handleChange('pax', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Flight Number"
          value={formFields.flightNumber}
          onChange={(event) => handleChange('flightNumber', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Fast Track"
          value={formFields.fastTrack}
          onChange={(event) => handleChange('fastTrack', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Airport"
          value={formFields.airport}
          onChange={(event) => handleChange('airport', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Arrival City"
          value={formFields.arrivalCity}
          onChange={(event) => handleChange('arrivalCity', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Date Booking"
          value={formFields.dateBooking}
          onChange={(event) => handleChange('dateBooking', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Drop Off"
          value={formFields.dropOff}
          onChange={(event) => handleChange('dropOff', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Add Trip"
          value={formFields.addTrip}
          onChange={(event) => handleChange('addTrip', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Activities"
          value={formFields.activities}
          onChange={(event) => handleChange('activities', event.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Comment"
          value={formFields.comment}
          onChange={(event) => handleChange('comment', event.target.value)}
        />
      </Grid>
    </>

  )
}