export const FirebaseConfig = {
	"projectId": "luxydrive-796b6",
	"appId": "1:51075743876:web:2d77ece3dc946cfee782d1",
	"databaseURL": "https://luxydrive-796b6-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "luxydrive-796b6.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBPx6FhNwKI4_0Gp31hf-qJw8pVLyQ0nhU",
	"authDomain": "luxydrive-796b6.firebaseapp.com",
	"messagingSenderId": "51075743876",
	"measurementId": "G-P4GCG1H03M"
};